import type { RouteRecordRaw } from "vue-router";

const InvoicingPage = () => import("../pages/invoicing/InvoicingPage.vue");
const InvoicesPage = () => import("../pages/invoicing/InvoicesPage.vue");
const ProfilesPage = () => import("../pages/invoicing/ProfilesPage.vue");
const PayeeProfilePage = () => import("../pages/invoicing/PayeeProfilePage.vue");
const CompanyProfilesPage = () => import("../pages/invoicing/CompanyProfilesPage.vue");
const NewInvoicePage = () => import("../pages/invoicing/NewInvoicePage.vue");
const EditInvoicePage = () => import("../pages/invoicing/EditInvoicePage.vue");

export const invoicingRoutes: RouteRecordRaw = {
  name: "invoicingPage",
  component: InvoicingPage,
  path: "/invoicing/:customerId",
  meta: {
    authenticated: true,
    flag: "hasInvoicingModule",
  },
  props: true,
  children: [
    {
      name: "invoicesPage",
      path: "invoices",
      component: InvoicesPage,
      props: true,
    },
    {
      name: "newInvoicePage",
      path: "invoices/new",
      component: NewInvoicePage,
    },
    {
      name: "editInvoicePage",
      path: "invoices/:invoiceId/edit",
      component: EditInvoicePage,
    },
    {
      name: "invoicingProfilesPage",
      path: "profiles",
      component: ProfilesPage,
      children: [
        {
          name: "payeeProfilePage",
          path: "payee",
          component: PayeeProfilePage,
        },
        {
          name: "companyProfilesPage",
          path: "companies",
          component: CompanyProfilesPage,
        },
      ],
    },
  ],
};
