<script setup lang="ts">
import AppButton from "@/components/app/AppButton.vue";
import { ButtonProps, colorClasses } from "./buttonProps";

withDefaults(defineProps<ButtonProps>(), {
  color: "primary",
  isSmall: false,
  isRounded: false,
  disabled: false,
  isCondensed: false,
});
</script>

<template>
  <AppButton
    :to="to"
    :class="colorClasses(color)"
    :leading-icon="leadingIcon"
    :trailing-icon="trailingIcon"
    :is-loading="isLoading"
    :is-small="isSmall"
    :is-rounded="isRounded"
    :is-condensed="isCondensed"
    :disabled="disabled"
  >
    <slot></slot>
  </AppButton>
</template>
