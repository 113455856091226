<script setup lang="ts">
import { LayoutBox } from "../layout";
</script>

<template>
  <LayoutBox as="header" class="flex flex-wrap items-center justify-between gap-3 p-6 sm:border-0 md:border">
    <div class="flex grow flex-col gap-y-3">
      <slot name="title" />
    </div>
    <div class="flex h-full flex-col justify-center gap-y-3">
      <slot name="actions" />
    </div>
  </LayoutBox>
</template>
