<script setup lang="ts">
type Props = {
  label: string;
  disabled?: boolean;
};

defineProps<Props>();
</script>

<template>
  <div class="flex items-center gap-1.5" :class="[disabled ? 'opacity-50' : '']">
    <span class="text-sm font-bold">{{ label }}</span>
    <span class="min-w-7 rounded-full bg-blue-500/20 px-2 py-1 text-center text-[10px] font-bold text-blue-800">
      <slot />
    </span>
  </div>
</template>
