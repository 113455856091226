<script setup lang="ts">
interface Props {
  as?: string;
}

withDefaults(defineProps<Props>(), {
  as: "div",
});
</script>
<template>
  <component :is="as" class="rounded-md border border-gray-400/50 bg-white">
    <slot></slot>
  </component>
</template>
