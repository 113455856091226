<script setup lang="ts">
import { LayoutBox } from ".";
interface Props {
  message: string;
}

defineProps<Props>();
</script>
<template>
  <LayoutBox class="flex items-center justify-start gap-6 px-4 py-6 shadow-lg">
    <span class="w-12 text-blue"><i class="fa-light fa-circle-notch fa-3x fa-spin"></i></span>
    <span class="whitespace-nowrap text-2xl">{{ message }}</span>
  </LayoutBox>
</template>
