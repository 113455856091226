<script setup lang="ts">
interface Props {
  labelClasses: string;
  value?: string[];
}

defineProps<Props>();
</script>

<template>
  <FormKit
    type="dropdown"
    data-testid="tags-input"
    name="tags"
    label="Tag(s)"
    placeholder="- -"
    multiple
    :classes="{
      wrapper: 'flex gap-1.5 w-full max-w-full',
      inner: 'grow min-w-0',
      truncationCount: 'bg-gray-400 rounded w-6',
      label: labelClasses,
    }"
    :options="[
      { label: 'Bad Order', value: 'Bad Order' },
      { label: 'Congestion Delay/Delay', value: 'Congestion Delay/Delay' },
      { label: 'Derailment', value: 'Derailment' },
      { label: 'Dirty Car/Pressure Wash', value: 'Dirty Car/Pressure Wash' },
      { label: 'Diversion', value: 'Diversion' },
      { label: 'Fuel', value: 'Fuel' },
      { label: 'Impact', value: 'Impact' },
      { label: 'Out of Route', value: 'Out of Route' },
      { label: 'Railcar Maintenance', value: 'Railcar Maintenance' },
      { label: 'Railroad Ticket', value: 'Railroad Ticket' },
      { label: 'Refrigeration Maintenance', value: 'Refrigeration Maintenance' },
      { label: 'Shop', value: 'Shop' },
      { label: 'Other', value: 'Other' },
    ]"
    :value="value"
  />
</template>
