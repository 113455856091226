import { reactive } from "vue";
import { defineStore } from "pinia";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

const useFeatureStore = defineStore("features", () => {
  const features = reactive({
    hasPricingModule: useLDFlag("pricing-module"),
    hasWatchtowerModule: useLDFlag("telematics-dashboard"),
    isPlatformNotificationVisible: useLDFlag("platform-notification"),
    hasWaybillEditCapability: useLDFlag("waybill-edits"),
    hasWaybillModule: useLDFlag("has-waybill-module"),
    hasInternalToolsModule: useLDFlag("has-internal-tools-module"),
    hasDashboardPage: useLDFlag("has-dashboard-page"),
    hasVisibilityFeatures: useLDFlag("has-visibility-features"),
    hasFleetCyclesModule: useLDFlag("has-fleet-cycles-module"), // module is under the visibility nav which is under FF above
    hasReportingModule: useLDFlag("has-reporting-module"),
    hasNetworkMapModule: useLDFlag("has-network-map-module"), // module is under the reporting nav which is also under FF above
    hasForecastingReportModule: useLDFlag("has-forecasting-report-module"), // module is under the reporting nav which is also under FF above
    hasPipelineViewReportModule: useLDFlag("has-pipeline-view-report-module"), // module is under the reporting nav which is also under FF above
    hasReportBuilderModule: useLDFlag("has-report-builder-module"), // module is under the reporting nav which is also under FF above
    hasTerminalModule: useLDFlag("has-terminal-module"),
    hasApWidget: useLDFlag("has-ap-widget"),
    hasReadyForReleaseWidget: useLDFlag("has-ready-for-release-widget"),
    hasDownloadPricingButton: useLDFlag("has-download-pricing-button"),
    hasCustomerSupportModule: useLDFlag("customer-support-module"),
    hasInteledeskForm: useLDFlag("has-inteledesk-form"),
    hasInvoicingModule: useLDFlag("invoicing-module"),
  });

  return {
    features,
  };
});

export default useFeatureStore;
