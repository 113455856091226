<script setup lang="ts">
import { OutlineButton, DialogModal } from "@/components/common";

interface Props {
  fileName: string;
  fileType: string;
  url: string;
  isActive: boolean;
}

defineProps<Props>();
defineEmits<{ (event: "download"): void; (event: "dismiss"): void }>();
</script>

<template>
  <DialogModal :is-active="isActive" @dismiss="$emit('dismiss')">
    <div
      class="flex h-screen w-screen max-w-screen-md flex-col overflow-clip border border-gray-800 bg-gray-200 sm:h-[80vh] md:rounded-lg"
    >
      <button
        data-test="modal-close"
        data-testid="modal-close"
        class="modal-close is-large tg-modal__close"
        aria-label="close"
        @click="$emit('dismiss')"
      ></button>
      <div class="flex grow items-center justify-center overflow-hidden py-2">
        <img v-if="fileType.startsWith('image/')" :src="url" class="max-h-full" />
        <video v-if="fileType.startsWith('video/')" :src="url" class="w-full" controls />
      </div>
      <div class="mt-auto flex w-full shrink-0 items-center justify-end border-t border-t-gray-300">
        <OutlineButton leading-icon="download" color="primary" class="mb-6 mr-6 mt-4" @click="$emit('download')">
          {{ fileName }}
        </OutlineButton>
      </div>
    </div>
  </DialogModal>
</template>
