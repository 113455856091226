import { SchemaNode } from "@telegraphio/tedi-view-builder/node";
import edi404TenderObj from "@telegraphio/tedi-view-builder/examples/edi.8030.404.everything.generic.json";
import ediOverrides from "@/edi.overrides.json";
import { SchemaBuilder } from "@telegraphio/tedi-view-builder/railmerge";
import { designList } from "@/types/ediBuilder";
import {
  FormKitNode,
  EquipmentAdapter,
  EquipmentReferenceAdapter,
  PartyIdentificationAdapter,
  LocationLookupAdapter,
  LocationFieldAdapter,
  CommoditiesAdapter,
  LineItemAndWeightAdapter,
  CommodityLookupAdapter,
  CarrierLookupAdapter,
  JunctionLookupAdapter,
  PriceAuthorityIdentificationAdapter,
  EmptyCarDispositionAdapter,
  ElementFlexRowAdapter,
  DropdownAdapter,
  DateAdapter,
  TimeAdapter,
  CheckboxAdapter,
  TemperatureUnitAdapter,
  BaseElementAdapter,
  BaseArrayAdapter,
  BaseObjectAdapter,
  ensureGrouping,
} from "@/formkit.adapter";
import type { PatternInputDetails } from "@telegraphio/papi-client";

const adapterList = [
  CarrierLookupAdapter,
  JunctionLookupAdapter,
  LocationLookupAdapter,
  LocationFieldAdapter,
  DropdownAdapter,
  DateAdapter,
  TimeAdapter,
  CheckboxAdapter,
  TemperatureUnitAdapter,
  EquipmentAdapter,
  EquipmentReferenceAdapter,
  PartyIdentificationAdapter,
  CommoditiesAdapter,
  LineItemAndWeightAdapter,
  CommodityLookupAdapter,
  PriceAuthorityIdentificationAdapter,
  EmptyCarDispositionAdapter,
  ElementFlexRowAdapter,
  BaseElementAdapter,
  BaseArrayAdapter,
  BaseObjectAdapter,
];

const exclusionList = [
  "beginning_segment_for_data_correction_or_change_ZC1",
  "interchange_control_header_ISA",
  "interchange_control_trailer_IEA",
  "functional_group_header_GS",
  "functional_group_trailer_GE",
  "loop_header_LS",
  "loop_trailer_LE",
  "transaction_set_header_ST",
  "transaction_set_trailer_SE",
  "release_M3",
  "general_shipment_information_BX.transaction_set_purpose_code_01",
  "transaction_set_line_number_LX_loop.transaction_set_line_number_LX.assigned_number_01",
  "party_identification_N1_loop.party_identification_N1.identification_code_qualifier_03",
];

function formatPath(path: string) {
  return path.replace(/\.\[\d+\]/g, "");
}

export function useEdiBuilder(pattern: string, inputDetails: PatternInputDetails[], isPattern: boolean) {
  const builder = new SchemaBuilder(edi404TenderObj, []);
  let schema: SchemaNode;

  const prefilledData = pattern ? JSON.parse(pattern) : {};

  const inputPathList = inputDetails.map((input) => {
    // Strip out index values for input paths that are within repeaters
    return formatPath(input.target || "");
  });

  const finalDesignList = [...new Set([...designList, ...inputPathList])];

  try {
    schema = builder.composeSchema(prefilledData, finalDesignList, ediOverrides, exclusionList);

    const designSchema = schema.root();

    const rendered = builder.renderToMap<FormKitNode>(designSchema, adapterList);

    for (const key in rendered) {
      rendered[key] = ensureGrouping(rendered[key], isPattern);
    }

    return { rendered };
  } catch (error) {
    console.log(error);
    return { rendered: {} };
  }
}
