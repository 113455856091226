<script setup lang="ts">
import type { AvatarSize } from ".";
type Props = {
  size: AvatarSize;
};
defineProps<Props>();
</script>

<template>
  <span
    :class="{
      'h-6 w-6 min-w-6': size === '1',
      'h-8 w-8 min-w-8': size === '2',
      'h-10 w-10 min-w-10': size === '3',
      'h-12 w-12 min-w-12': size === '4',
      'h-14 w-14 min-w-14': size === '5',
    }"
    class="inline-block overflow-hidden rounded-full bg-gray-200/35"
  >
    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
      <path
        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
  </span>
</template>
