<script setup lang="ts">
interface Props {
  status: "active" | "success" | "pending" | "failure" | "inactive";
  align?: "bottom" | "left" | "right";
  role?: "primary" | "secondary";
  isSmall?: boolean;
}

withDefaults(defineProps<Props>(), {
  align: "left",
  role: "primary",
});
</script>

<template>
  <div
    class="max-w-max whitespace-nowrap rounded-none p-1.5 font-semibold"
    :class="[
      isSmall ? 'text-xs' : 'text-lg',
      align === 'bottom' ? 'border-b-8' : align === 'right' ? 'border-r-8' : 'border-l-8',
      status === 'active'
        ? 'border-green-teal bg-green-teal/40'
        : status === 'success'
          ? 'border-green-mint-800 bg-green-mint'
          : status === 'pending'
            ? 'border-yellow-500 bg-yellow-500/30'
            : status === 'failure'
              ? 'border-red-500 bg-red-100'
              : 'border-gray-800 bg-gray-300',
    ]"
  >
    <slot />
  </div>
</template>
