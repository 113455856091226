<script setup lang="ts">
import type { AvatarSize } from ".";
type Props = {
  initials: string;
  size: AvatarSize;
};
defineProps<Props>();
</script>

<template>
  <span
    :class="{
      'h-6 w-6 min-w-6': size === '1',
      'h-8 w-8 min-w-8': size === '2',
      'h-10 w-10 min-w-10': size === '3',
      'h-12 w-12 min-w-12': size === '4',
      'h-14 w-14 min-w-14': size === '5',
    }"
    class="inline-flex items-center justify-center rounded-full bg-gray-500"
  >
    <span class="text-xs font-medium leading-none text-white">{{ initials }}</span>
  </span>
</template>
