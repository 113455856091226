<script setup lang="ts">
import type { AvatarSize } from ".";

type Props = {
  size: AvatarSize;
  url: string;
};
defineProps<Props>();
defineEmits(["error"]);
</script>

<template>
  <img
    class="inline-block shrink-0 rounded-full"
    :class="{
      'h-6 w-6 min-w-6': size === '1',
      'h-8 w-8 min-w-8': size === '2',
      'h-10 w-10 min-w-10': size === '3',
      'h-12 w-12 min-w-12': size === '4',
      'h-14 w-14 min-w-14': size === '5',
    }"
    :src="url"
    alt="User profile avatar"
    @error="$emit('error')"
  />
</template>
