import { useDebounceFn } from "@vueuse/core";
import { ref, computed, reactive, watch } from "vue";
import { defineStore } from "pinia";
import { VisibilityAsset } from "@/bapi-client/types/assets/visibilityAsset";
import { useUserStore } from "@/stores/user";
import useNotificationStore from "@/stores/notifications";
import useTrackerStore from "@/stores/trackers";
import { useFetch } from "@/composables/fetch";
import { usePaged } from "@/composables/paged";
import { useReportingGroups } from "@/composables/reportingGroups";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import { Option } from "@/types";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";

export const useAssetStore = defineStore("assets-setup", () => {
  const notifier = useNotificationStore();
  const trackers = useTrackerStore();
  const userStore = useUserStore();
  const assets = ref<VisibilityAsset[]>([]);
  const bulkEquipmentIds = ref("");
  const mode = ref<"BULK" | "DEFAULT">("DEFAULT");
  const shouldKeepSelectedReportingGroups = ref(true);

  const isLoading = ref(false);
  const { page, total } = usePaged();

  const totalPages = computed(() => total.pages);
  const totalAssets = computed(() => total.records);
  const assetStatus = ref("active");
  const { reportingGroups } = useReportingGroups();

  interface AssetFilters {
    reportingGroupIds: string[];
    originIds: string[];
    destinationIds: string[];
    statusCode: string;
    loadEmptyStatus: "L" | "E" | "";
    reportingScac: string;
    maxDwell: number;
    minDwell: number;
    hasExceptions: string;
  }

  const activeStatuses = userStore.company?.waybillType === "X" ? "0,20,30,40,60,80,90" : "0,10,40,50,70,90";

  const filters = reactive<AssetFilters>({
    reportingGroupIds: [],
    originIds: [],
    destinationIds: [],
    statusCode: activeStatuses,
    loadEmptyStatus: "",
    reportingScac: "",
    minDwell: 0,
    maxDwell: 0,
    hasExceptions: "",
  });

  const debouncedFetch = useDebounceFn(() => {
    if (mode.value === "BULK") {
      return bulkSearch();
    }
    return getAssets();
  }, 400);

  watch(filters, () => {
    if (page.current > 1) {
      page.current = 1;
      return undefined;
    }
    debouncedFetch();
  });

  watch(
    () => page.current,
    () => {
      debouncedFetch();
    },
  );

  function logFilter(filter: string) {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.VISIBILITY_ASSET_FILTER, {
      filter,
    });
  }

  function resetFilters() {
    if (!shouldKeepSelectedReportingGroups.value) {
      filters.reportingGroupIds = [];
    }
    filters.originIds = [];
    filters.destinationIds = [];
    filters.statusCode = activeStatuses;
    filters.loadEmptyStatus = "";
    filters.reportingScac = "";
    filters.minDwell = 0;
    filters.maxDwell = 0;
    filters.hasExceptions = "all";
  }

  const hasFilters = computed(() => {
    let out = false;
    for (const filter of Object.values(filters)) {
      if (typeof filter === "string" || Array.isArray(filter)) {
        if (filter === "all") continue;
        out = filter.length > 0;
      }

      if (typeof filter === "number") {
        out = filter > 0;
      }

      if (filter === undefined) {
        out = false;
      }

      if (out === true) break;
    }

    return out;
  });

  // const columns = [
  //   { label: "Asset", value: "statusCode" },
  //   { label: "L/E", value: "loadEmptyStatus" },
  //   { label: "Commodity", value: "commodityDescription" },
  //   "Reporting Groups",
  //   { label: "Origin", value: "originLocation" },
  //   { label: "Current Location", value: "currentLocation" },
  //   { label: "Destination", value: "destinationLocation" },
  //   { label: "Carrier", value: "reportingScac" },
  //   { label: "Dwell", value: "daysSinceLastSighting" },
  //   "Exceptions",
  // ];

  const columns = [
    "Asset",
    "L/E",
    "Commodity",
    "Reporting Groups",
    "Origin",
    "Current Location",
    "Destination",
    "Carrier",
    "Dwell",
    "Exceptions",
  ];

  // const mobileColumns = [
  //   { label: "Asset", value: "statusCode" },
  //   { label: "Current Location", value: "currentLocation" },
  //   { label: "L/E", value: "loadEmptyStatus" },
  //   { label: "Commodity", value: "commodityDescription" },
  //   "Reporting Groups",
  //   { label: "Origin", value: "originLocation" },
  //   { label: "Destination", value: "destinationLocation" },
  //   { label: "Carrier", value: "reportingScac" },
  //   { label: "Dwell", value: "daysSinceLastSighting" },
  //   "Exceptions",
  // ];

  const mobileColumns = [
    "Asset",
    "L/E",
    "Current Location",
    "Commodity",
    "Reporting Groups",
    "Origin",
    "Destination",
    "Carrier",
    "Dwell",
    "Exceptions",
  ];

  const tableColumns = computed(() => {
    if (/Android|iPhone/i.test(navigator.userAgent)) return mobileColumns;
    return columns;
  });

  const activeCompleteStatus: Option[] = [
    { label: "Active", value: "active" },
    { label: userStore.company?.waybillType === "R" ? "Complete" : "Outgate", value: "complete" },
  ];

  type AssetRequestBody = {
    page: number;
    page_size: number;
    reporting_group_ids: string;
    origin: string;
    destination: string;
    load_empty_status: "L" | "E" | "";
    current_carrier: string;
    status: string;
    min_dwell?: number;
    max_dwell?: number;
    has_exceptions?: boolean;
  };

  async function getAssets() {
    isLoading.value = true;
    notifier.setLoading("Loading assets");
    const postBody: AssetRequestBody = {
      page: page.current,
      page_size: page.size,
      reporting_group_ids: filters.reportingGroupIds?.join(","),
      origin: filters.originIds.join(","),
      destination: filters.destinationIds.join(","),
      load_empty_status: filters.loadEmptyStatus,
      current_carrier: filters.reportingScac,
      status: filters.statusCode.length ? filters.statusCode : "0,10,40,50,70,90",
    };

    if (postBody.status && postBody.status.includes("40") && !postBody.status.includes("50")) {
      postBody.status += ",50";
    }

    if (filters.minDwell > 0) {
      postBody.min_dwell = filters.minDwell;
    }

    if (filters.maxDwell > 0) {
      postBody.max_dwell = filters.maxDwell;
    }

    if (filters.hasExceptions && filters.hasExceptions !== "all") {
      postBody.has_exceptions = filters.hasExceptions === "true" ? true : false;
    }
    const response = await useBapi(BAPI_COMMANDS.GET_VISIBILITY_ASSETS, userStore.companyId, postBody);

    notifier.setLoading();
    isLoading.value = false;
    if (!response.success) {
      notifier.setToast("danger", "Unable to load assets at this time.");
      console.error("Request get assets failed with ", response.error);
      return undefined;
    }

    total.records = response.data.total_record_count;
    total.pages = response.data.number_of_pages;
    page.total = response.data.current_page_size;
    assets.value = response.data.data;
  }

  async function bulkSearch() {
    if (mode.value === "DEFAULT") {
      mode.value = "BULK";
    }
    isLoading.value = true;
    notifier.setLoading("Searching assets...");
    const response = await useBapi(BAPI_COMMANDS.GET_VISIBILITY_ASSETS, userStore.companyId, {
      page: page.current,
      page_size: page.size,
      equipment_ids: bulkEquipmentIds.value.split("\n").join(","),
    });
    notifier.setLoading();
    isLoading.value = false;

    const trackers = useTrackerStore();
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.VISIBILITY_ASSET_BULK_SEARCH, {
      equipment_ids: bulkEquipmentIds.value.split("\n").join(","),
    });

    if (!response.success) {
      notifier.setToast("info", "No assets found.");
      console.error("Bulk search failed: ", response.error);
      return undefined;
    }

    total.records = response.data.total_record_count;
    total.pages = response.data.number_of_pages;
    assets.value = response.data.data;
  }

  async function getCSV() {
    notifier.setLoading("Exporting to CSV");
    const request = useFetch();
    const postBody: Record<string, string | number | boolean> = {
      reporting_group_ids: filters.reportingGroupIds.join(","),
      origin: filters.originIds.join(","),
      destination: filters.destinationIds.join(","),
      load_empty_status: filters.loadEmptyStatus,
      current_carrier: filters.reportingScac,
      status: filters.statusCode.length ? filters.statusCode : "0,10,40,50,70,90",
    };
    if (userStore.company?.waybillType === "X") {
      postBody.statusCode = filters.statusCode.length ? filters.statusCode : "0,20,30,40,60,80,90";
    }

    if (mode.value === "BULK") {
      postBody.equipment_ids = bulkEquipmentIds.value.split("\n").join(",");
    }

    if (filters.minDwell > 0) {
      postBody.min_dwell = filters.minDwell;
    }

    if (filters.maxDwell > 0) {
      postBody.max_dwell = filters.maxDwell;
    }

    if (filters.hasExceptions && filters.hasExceptions !== "all") {
      postBody.has_exceptions = filters.hasExceptions === "true" ? true : false;
    }

    const selected_filters: Record<string, unknown> = {};
    for (const filter in filters) {
      selected_filters[filter] = filters[filter as keyof typeof filters];
    }

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.VISIBILITY_ASSET_DASHBOARD_DOWNLOAD_CSV, {
      selected_filters,
    });

    const response = await request.post(`/waybills/${userStore.companyId}/dashboard/csv`, {
      body: postBody,
    });

    notifier.setLoading();
    if (!response.ok) {
      notifier.setToast("danger", "Unable to load CSV at this time.");
      console.error("CSV export failed: ", response.status);
      return undefined;
    }

    const blob = await response.blob().catch((error: Error) => {
      console.error("Failed to parse returned file: ", error);
      return error;
    });

    if (blob instanceof Error) {
      return undefined;
    }

    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement("a");
    link.href = url;
    link.download = response.headers.get("downloadable_as") ?? "csv_export.csv";
    window.document.body.append(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  return {
    shouldKeepSelectedReportingGroups,
    tableColumns,
    assetStatus,
    activeCompleteStatus,
    reportingGroups,
    assets,
    getAssets,
    bulkSearch,
    filters,
    resetFilters,
    hasFilters,
    getCSV,
    isLoading,
    totalPages,
    totalAssets,
    logFilter,
    bulkEquipmentIds,
    mode,
  };
});
