<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";

defineProps<{ to?: RouteLocationRaw; active?: boolean }>();
</script>

<template>
  <li class="group">
    <router-link
      :to="to ?? '#'"
      :class="{
        'pointer-events-none font-semibold text-gray-900': active,
        'cursor-pointer font-bold text-blue hover:underline': to && !active,
        'pointer-events-none font-semibold text-gray-400': !to,
      }"
      :aria-current="active ? 'page' : ''"
      class="flex items-center gap-1 whitespace-nowrap text-sm"
    >
      <slot />
      <span class="ml-2 text-sm text-gray-500/75 group-last:hidden" aria-hidden="true">
        <i class="fa-solid fa-angle-right"></i>
      </span>
    </router-link>
  </li>
</template>
