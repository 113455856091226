<script setup lang="ts">
import AppButton from "../app/AppButton.vue";
import { ButtonProps } from "./buttonProps";

type TextButtonProps = Omit<ButtonProps, "isRounded">;

withDefaults(defineProps<TextButtonProps>(), {
  color: "primary",
  isSmall: false,
  isRounded: false,
});
</script>

<template>
  <AppButton
    :is-small="isSmall"
    :is-loading="isLoading"
    :to="to"
    :disabled="disabled"
    :leading-icon="leadingIcon"
    :trailing-icon="trailingIcon"
    :class="[
      color === 'primary' ? 'text-blue hover:border-blue-600 hover:text-blue-600' : '',
      color === 'primary-dark' ? 'text-blue-royal hover:border-blue-royal-600 hover:text-blue-royal-600' : '',
      color === 'primary-light' ? 'text-blue-sky-700 hover:border-blue-sky-800 hover:text-blue-sky-800' : '',
      color === 'info' ? 'text-blue-sky hover:border-blue-sky-600 hover:text-blue-sky-600' : '',
      color === 'success' ? 'text-green-teal hover:border-green-teal-600 hover:text-green-teal-600' : '',
      color === 'success-light' ? 'text-green-mint hover:border-green-mint-600 hover:text-green-mint-600' : '',
      color === 'danger' ? 'text-red-500 hover:border-red-600 hover:text-red-600' : '',
    ]"
    class="active:text-primary rounded-none border-b border-transparent px-0 pb-0 pt-0 hover:border-b active:border-transparent active:!shadow-none"
  >
    <slot />
  </AppButton>
</template>
