<script setup lang="ts">
import { ref, computed } from "vue";
import { useBapi } from "@/bapi-client";
import { Option } from "@/types";
import { ProfilePicture } from "@/components/common";
import { BAPIAutocompleteUser } from "@/bapi-client/types/autocomplete";

type Props = {
  customerId: string;
  flat?: boolean;
  name: string;
  required?: boolean;
  value?: BAPIAutocompleteUser;
};

const props = defineProps<Props>();
const profilePictureURLs = ref<Map<string, string>>(new Map());
const optionList = ref<Option[]>([]);
const fullName = computed(() => (props.value ? `${props.value?.first_name} ${props.value?.last_name}` : ""));

async function searchUsers({ search }: { search: string }): Promise<Option[]> {
  if (!search || search.length < 3) {
    return optionList.value;
  }

  const result = await useBapi("autocompleteUsers", props.customerId, {
    searchTerm: search,
  });

  if (!result.success) {
    return optionList.value;
  }

  const out: Option[] = [];
  for (const i of result.data) {
    if (!optionList.value.find((opt) => opt.value === i.userId)) {
      profilePictureURLs.value.set(i.userId, i.profilePicture.thumbnailUrl);
      out.push({
        label: i.fullName,
        value: i.userId,
      });
    }
  }

  return optionList.value.concat(out);
}

async function optionLoader(value: string, cached?: Option): Promise<Option> {
  if (props.value) {
    profilePictureURLs.value.set(props.value.user_id, props.value.profile_picture.thumbnail_url);
    return {
      label: `${props.value.first_name} ${props.value.last_name}`,
      value: props.value.user_id,
    };
  }

  if (cached) {
    if (!optionList.value.find((opt) => opt.value === cached.value)) {
      optionList.value.push(cached);
    }
    return cached;
  }

  const result = await useBapi("autocompleteUsers", props.customerId, {
    searchTerm: fullName.value || value,
  });

  if (!result.success || !result.data.length) {
    return { label: "No matching users found.", value: "" };
  }

  const out: Option[] = [];
  for (const i of result.data) {
    profilePictureURLs.value.set(i.userId, i.profilePicture.thumbnailUrl);
    out.push({
      label: i.fullName,
      value: i.userId,
    });
  }

  optionList.value.concat(out);
  return out[0];
}
</script>

<template>
  <FormKit
    :name="name"
    type="autocomplete"
    :close-on-select="true"
    selection-appearance="option"
    loader-icon="loader"
    :delay="300"
    :validation="required ? 'required' : ''"
    :classes="{
      inner: `${flat ? 'rounded-none shadow-inner w-56' : 'w-56'}`,
      dropdownWrapper: 'rounded-none',
      listbox: 'rounded-none border-2',
      option: 'rounded-none',
      loadMoreInner: 'hidden',
      selectionWrapper: 'bg-white border-none rounded-none m-px justify-between',
    }"
    :options="searchUsers"
    :option-loader="optionLoader"
    :value="value?.user_id"
    empty-message="No users found."
    placeholder="Search Name"
  >
    <template #option="{ option }">
      <div class="flex h-8 w-full items-center gap-1.5 p-0">
        <ProfilePicture size="1" :url="profilePictureURLs.get(option.value) ?? ''" />
        <span class="font-medium">{{ option.label }}</span>
      </div>
    </template>
  </FormKit>
</template>
