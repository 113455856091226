<script setup lang="ts">
import { ButtonProps } from "../common/buttonProps";

type BaseProps = Pick<
  ButtonProps,
  "disabled" | "to" | "isSmall" | "isCondensed" | "isRounded" | "isLoading" | "leadingIcon" | "trailingIcon"
>;

defineProps<BaseProps>();
</script>

<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to ?? undefined"
    :class="[
      disabled ? 'pointer-events-none cursor-default opacity-50' : '',
      isRounded ? 'rounded-full px-4' : 'rounded-md',
      isSmall
        ? 'h-8 p-3 text-base font-semibold'
        : isCondensed
          ? 'h-7 p-2 text-sm font-normal'
          : 'h-11 p-3 text-lg font-semibold',
    ]"
    :disabled="disabled"
    class="relative inline-flex grow-0 cursor-pointer items-center gap-1 font-semibold shadow-none transition-colors active:shadow-inner"
  >
    <template v-if="leadingIcon && !isLoading">
      <span :class="[isSmall || isCondensed ? 'text-sm' : 'text-base']">
        <i :class="'fa-regular fa-' + leadingIcon" />
      </span>
    </template>
    <span v-if="isLoading" :class="[isSmall ? 'text-sm' : 'text-base']" class="absolute left-[calc(50%-.50rem)] z-20">
      <i class="fa-solid fa-spinner-third animate-spin" />
    </span>
    <span
      class="whitespace-nowrap"
      :class="[isLoading ? 'text-transparent opacity-0' : '', { 'w-full text-center': !trailingIcon && !leadingIcon }]"
    >
      <slot></slot>
    </span>
    <template v-if="trailingIcon && !isLoading">
      <span :class="[isSmall || isCondensed ? 'text-sm' : 'text-base']" class="ml-auto">
        <i :class="'fa-regular fa-' + trailingIcon" />
      </span>
    </template>
  </component>
</template>
