<script setup lang="ts">
import { computed, onMounted, provide, ref, unref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { LayoutBox } from "@/components/layout";
import ProgressStep from "./ProgressStep.vue";
import useFormPlugins from "@/composables/waybillFormPlugins";
import { useEdiBuilder } from "@/composables/ediBuilder";
import { usePapiClient } from "@/composables/papiClient";
import { FormKitNode, getNode, submitForm } from "@formkit/core";
import useNotificationStore from "@/stores/notifications";
import { useBapi } from "@/bapi-client";

import type { PatternInputDetails } from "@telegraphio/papi-client";
import type { InputOptionsData } from "@/types/ediBuilder";

import TgButton from "@/components/common/TgButton.vue";
import SidebarButtonWithPopover from "./SidebarButtonWithPopover.vue";

const props = defineProps<{
  companyId: string;
  patternId?: string;
}>();

const inputDetails = ref<PatternInputDetails[]>([]);

const isPattern = computed(() => route.name === "createPattern" || route.name === "editPattern");

const route = useRoute();
const router = useRouter();
const notifier = useNotificationStore();
const trackers = useTrackerStore();
const { steps, buildPath, stepPlugin, inputPlugin, locationPlugin, commodityPlugin, partyPlugin } = useFormPlugins(
  inputDetails,
  isPattern.value,
);
const { papiApi } = usePapiClient();

const isNew = route.name === "createPattern";

const patternForm = ref();
const documentTitle = ref("");
const schemaMap = ref<Record<string, any>>({});
const isSaving = ref(false);

const segmentOrder = [
  "general_shipment_information_BX",
  "rail_shipment_information_BNX",
  "release_M3",
  "extended_reference_information_N9",
  "equipment_details_N7_loop",
  "transaction_set_line_number_LX_loop",
  "party_identification_N1_loop",
  "origin_station_F9",
  "destination_station_D9",
  "route_information_R2",
  "protective_service_instructions_PS",
  "empty_car_disposition_pended_destination_consignee_E1_loop",
];

const sortedSchema = computed(() => {
  return Object.entries(schemaMap.value).sort(([a], [b]) => {
    const aIndex = segmentOrder.indexOf(a);
    const bIndex = segmentOrder.indexOf(b);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });
});

const firstStep = computed(() => Object.keys(steps)[0]);
const isPatternFormValid = computed(() => patternForm?.value?.node.context.state.valid);

const schemaData = ref({
  variableCheckboxAttributes: {
    onChange: (e: Event) => {
      const target = e.target as HTMLInputElement;
      const id = target.id;

      const checkboxNode = getNode(id);

      if (!checkboxNode) {
        console.log("No checkbox node found for id:", id);
        return;
      }

      // Strip off '-checkbox' from the end of the name
      const fieldName = checkboxNode.name.slice(0, checkboxNode.name.length - 9);
      const node = checkboxNode.parent?.children.find((child) => child.name === fieldName);

      if (!node) {
        console.log("No input node found with name:", fieldName);
        return;
      }

      if (target.checked) {
        node.props.attrs.optionRequired = true;
      } else {
        delete node.props.attrs.optionRequired;
      }
    },
  },
  searchSttcs: async ({ search }: { search: string }) => {
    const result = await useBapi("getStccs", {
      searchTerm: search,
      context: "pricing",
    });

    if (!result.success) {
      return [];
    }

    const seenSTCCs = new Set<string>();

    return result.data.reduce(
      (acc, stcc) => {
        if (seenSTCCs.has(stcc.associatedSTCC)) {
          return acc;
        }

        seenSTCCs.add(stcc.associatedSTCC);

        const value = `${stcc.associatedSTCC} - ${stcc.commodity}`;

        return [...acc, { label: value, value }];
      },
      [] as { label: string; value: string }[],
    );
  },
  searchLocations: async ({ search }: { search: string }) => {
    const result = await useBapi("getAllLocations", {
      search,
    });

    if (!result.success) {
      return [];
    }

    return result.data.map((location) => {
      const formattedLocation = `${location.city}, ${location.state}, ${location.country}`;

      return {
        label: formattedLocation,
        value: formattedLocation,
      };
    });
  },
  searchJunctions: async ({ search }: { search: string }) => {
    if (!search) return [];

    const result = await useBapi("getAllLocations", {
      search,
      only_junctions: true,
    });

    if (!result.success) {
      return [];
    }

    return result.data.map((location) => location.junction_abbreviation);
  },
  searchScacs: async ({ search }: { search: string }) => {
    const result = await useBapi("autocompleteScacs", props.companyId, search);

    if (!result.success) {
      return [];
    }

    return result.data;
  },
  searchCifs: async ({ search }: { search: string }) => {
    try {
      const result = await papiApi.bookingGetCIFByFuzzyQuery({
        getCIFFuzzyQueryRequest: { term: search },
      });

      const { results = [] } = result;

      return results.map((customer) => {
        const { address1, address2, city, country, customerName, postal, state } = customer;

        return {
          label: `${customerName} - ${address1}, ${address2 ? address2 + ", " : ""}${city}, ${state}, ${postal}, ${country}`,
          value: customer,
        };
      });
    } catch (error) {
      return [];
    }
  },
});

function updateVariableInputData(data: InputOptionsData) {
  if (data.label) {
    const node = getNode(data.fieldId);
    if (!node) return;

    node.props.attrs.originalLabel = node.props.label;
    node.props.label = data.label;
    node.props.labelClass = "text-blue-400";
    node.props.attrs.optionLabel = data.label;
  }
}

function resetInputData(fieldId: string) {
  const node = getNode(fieldId);
  if (!node) return;

  node.props.label = node.props.attrs.originalLabel;
  node.props.labelClass = "";
  delete node.props.attrs.optionLabel;
  delete node.props.attrs.originalLabel;
}

provide("editEdiFormData", { isVisible: isPattern.value, updateVariableInputData, resetInputData });

function handleTrackCancel() {
  const rsEvent = isPattern.value
    ? isNew
      ? RUDDERSTACK_EVENTS.WAYBILLING_NEW_PATTERN_CANCEL
      : RUDDERSTACK_EVENTS.WAYBILLING_EDIT_PATTERN_CANCEL
    : RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_CANCEL;

  trackers.logRudderstackEvent(rsEvent, { pattern_id: props.patternId });
}

function walkSchema() {
  const stack: FormKitNode[] = [patternForm.value.node];
  const inputList: PatternInputDetails[] = [];

  const excludeTypes = ["form", "group", "repeater"];

  while (stack.length) {
    const node = stack.pop();

    if (!node) continue;

    if (node.children.length) {
      node.children.forEach((child) => {
        stack.push(child as FormKitNode);
      });
      continue;
    }

    if (excludeTypes.includes(node.props.type) || node.name.endsWith("-checkbox") || node.name.endsWith("-lookup")) {
      continue;
    }

    const inputOptions: PatternInputDetails = {
      name: node.props.attrs.optionLabel ?? node.name,
      target: buildPath(node),
      optional: !node.props.attrs.optionRequired,
    };

    if (node.value) {
      inputOptions._default = node.value as string;
    }

    inputList.push(inputOptions);
  }

  return inputList;
}

function formatData(tender: Record<string, any>) {
  const stack: FormKitNode[] = [patternForm.value.node];

  while (stack.length) {
    const node = stack.pop();

    if (!node) continue;
    if (!node.value) continue;

    if (node.children.length) {
      node.children.forEach((child) => {
        stack.push(child as FormKitNode);
      });
      continue;
    }

    if (node.props.type !== "date" && node.props.type !== "time") {
      continue;
    }

    let path = buildPath(node);
    path = path.replace(/\[/g, "").replace(/\]/g, "");

    const value = node.value as string;
    const dateValue =
      node.props.type === "date"
        ? new Date(`${value}T00:00:00Z`)
        : new Date(`${new Date().toISOString().slice(0, 11)}${value}:00Z`);

    path.split(".").reduce((acc, key, index, array) => {
      if (index === array.length - 1) {
        acc[key] = dateValue;
      } else {
        return acc[key];
      }
    }, tender);
  }
}

function handleSubmit(data: any) {
  if (isPattern.value) {
    handleSavePattern(data);
  } else {
    handleSubmitTender(data);
  }
}

async function handleSavePattern(data: any) {
  const rsEvent = isNew
    ? RUDDERSTACK_EVENTS.WAYBILLING_NEW_PATTERN_CREATE
    : RUDDERSTACK_EVENTS.WAYBILLING_EDIT_PATTERN_SAVE;

  try {
    isSaving.value = true;

    // The FormKit output contains nested duplicate keys, so we need to flatten it
    const pattern = Object.keys(data).reduce((acc, key) => {
      return { ...acc, [key]: data[key][key] };
    }, {});

    const inputDetailsList = walkSchema();

    const response = await papiApi.bookingUpsertPattern({
      customerId: props.companyId,
      upsertPatternRequest: {
        patternId: props.patternId,
        pattern: JSON.stringify(pattern),
        label: documentTitle.value,
        inputs: inputDetailsList,
      },
    });

    trackers.logRudderstackEvent(rsEvent, {
      success: true,
      pattern_id: isNew ? response.patternId : props.patternId,
      pattern_name: documentTitle.value,
    });

    notifier.setToast("success", "Pattern saved successfully");
    router.push({ name: "patternsList" });
  } catch (error) {
    trackers.logRudderstackEvent(rsEvent, {
      success: false,
      pattern_id: props.patternId,
    });

    notifier.setToast("danger", "Failed to save pattern");
  } finally {
    isSaving.value = false;
  }
}

async function handleSubmitTender(data: any) {
  try {
    isSaving.value = true;

    const tender = Object.keys(data).reduce((acc, key) => {
      return { ...acc, [key]: data[key][key] };
    }, {});

    formatData(tender);

    const response = await papiApi.bookingCreateTenderCustomer({
      customerId: props.companyId,
      createTenderRequest: {
        waybill: tender,
      },
    });

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_SUBMIT, {
      success: true,
      pattern_id: props.patternId,
      tender_request_id: response.requestId,
    });

    notifier.setToast("success", "Tender sumbitted successfully");
    router.push({ name: "waybillsList" });
  } catch (error) {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_SUBMIT, {
      success: false,
      pattern_id: props.patternId,
    });

    notifier.setToast("danger", "Failed to submit tender");
  } finally {
    isSaving.value = false;
  }
}

onMounted(async () => {
  let patternString = "";

  if (!isNew) {
    const response = await papiApi.bookingGetPattern({
      customerId: props.companyId,
      getPatternRequest: { patternId: props.patternId },
    });

    const { label, pattern, inputs } = response;

    documentTitle.value = label ?? "";
    patternString = pattern ?? "";
    inputDetails.value = inputs ?? [];
  }

  const { rendered } = useEdiBuilder(patternString, inputDetails.value, isPattern.value);
  schemaMap.value = rendered;

  const groups = document.querySelectorAll(".waybill-form-group");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const { id } = entry.target;

      if (entry.isIntersecting) {
        steps[id].seen = true;
      }
    });
  });

  groups.forEach((group) => {
    observer.observe(group);
  });
});
</script>

<template>
  <div class="flex overflow-y-auto">
    <LayoutBox class="flex w-52 flex-col justify-between p-3">
      <div class="mb-3 overflow-auto">
        <ProgressStep
          v-for="(step, key) in steps"
          :key="key"
          :label="key"
          :is-valid="unref(step.valid)"
          :is-submitted="unref(step.submitted)"
          :seen="step.seen"
          :show-stem="key !== firstStep"
        />
      </div>
      <div class="flex flex-col gap-3">
        <SidebarButtonWithPopover
          v-if="isPattern"
          v-model="documentTitle"
          entity="pattern"
          :is-loading="isSaving"
          :disabled="!isPatternFormValid"
          @save="submitForm('pattern-form')"
        >
          <TgButton class="w-full" is-small :disabled="!isPatternFormValid">Save Pattern</TgButton>
        </SidebarButtonWithPopover>
        <TgButton
          v-else
          data-testid="submit-tender"
          is-small
          :is-loading="isSaving"
          color="primary"
          class="w-full"
          @click="submitForm('pattern-form')"
        >
          Submit
        </TgButton>
        <router-link :to="{ name: 'patternsList' }">
          <TgButton is-small class="w-full bg-red-200 hover:bg-red-300" @click="handleTrackCancel">Cancel</TgButton>
        </router-link>
      </div>
    </LayoutBox>
    <div class="w-full overflow-y-scroll pl-3">
      <LayoutBox class="mb-6 p-3">
        <ul v-if="isPattern" class="list-disc">
          <li class="list-inside text-sm">Any fields filled out will persist when booking, but remain editable</li>
          <li class="list-inside text-sm">To require a field when booking, check the box next to it</li>
          <li class="list-inside text-sm">To edit a label, click any label that has a blue pencil</li>
        </ul>
        <ul v-else class="list-disc">
          <li class="list-inside text-sm">
            Any fields filled out have been taken from the pattern, but remain editable
          </li>
          <li class="list-inside text-sm">Fields with ✱ are required for submission</li>
        </ul>
      </LayoutBox>
      <FormKit
        id="pattern-form"
        ref="patternForm"
        type="form"
        :config="{ validationVisibility: 'submit' }"
        :plugins="[stepPlugin, inputPlugin, locationPlugin, commodityPlugin, partyPlugin]"
        :actions="false"
        @submit="handleSubmit"
      >
        <section
          v-for="[groupName, node] in sortedSchema"
          :id="groupName"
          :key="groupName"
          class="waybill-form-group mb-12"
        >
          <FormKit :id="groupName" type="group" :name="groupName">
            <FormKitSchema :schema="node" :data="schemaData" />
          </FormKit>
        </section>
      </FormKit>
    </div>
  </div>
</template>
