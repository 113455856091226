import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { AssetHistoryResponse } from "@/bapi-client/types/assets/assetHistory";
import { fetchHandler } from "@/bapi-client/fetch";
import type { Result } from "@/bapi-client/types";
import { BAPIGetVisibilityAssetsResponse, VisibilityAssetFilters } from "@/bapi-client/types/assets/visibilityAsset";

export async function getAssetHistory(
  customerId: string,
  assetId: string,
  params: {
    destination?: string;
    load_empty_status?: string;
    max_date_str?: string;
    min_date_str?: string;
    origin?: string;
    page?: number;
    page_size?: number;
  },
): Promise<Result<AssetHistoryResponse>> {
  const path = `/assets/${customerId}/${assetId}`;

  const fetchResult = await fetchHandler(BAPI_COMMANDS.GET_ASSET_HISTORY, "POST", path, {
    body: params,
  });

  if (!fetchResult.success) {
    return fetchResult;
  }

  const jsonResult = await fetchResult.data.json();
  if (!jsonResult.success) return jsonResult;

  return {
    success: true,
    data: jsonResult.data,
  };
}

export async function getVisiblityAssets(
  customerId: string,
  filters: VisibilityAssetFilters,
): Promise<Result<BAPIGetVisibilityAssetsResponse>> {
  const path = `/waybills/${customerId}/dashboard`;
  const body = {
    ...filters,
  };

  const result = await fetchHandler(BAPI_COMMANDS.GET_VISIBILITY_ASSETS, "POST", path, {
    body,
  });
  if (!result.success) {
    return result;
  }
  const jsonResult = await result.data.json();
  if (!jsonResult.success) {
    return jsonResult;
  }
  return {
    success: jsonResult.success,
    data: jsonResult.data,
  };
}
