<script setup lang="ts">
import AppButton from "@/components/app/AppButton.vue";
import { ButtonProps } from "./buttonProps";

defineProps<ButtonProps>();
</script>

<template>
  <AppButton
    :color="color"
    :is-small="isSmall"
    :is-condensed="isCondensed"
    :leading-icon="leadingIcon"
    :trailing-icon="trailingIcon"
    :is-loading="isLoading"
    :is-rounded="isRounded"
    :disabled="disabled"
    :class="[
      'border bg-transparent transition',
      color === 'danger' ? 'border-red-500 text-red-500 hover:bg-red-500 hover:text-white' : '',
      color === 'primary' ? 'border-blue text-blue hover:bg-blue hover:text-white' : '',
      color === 'primary-dark' ? 'border-blue-royal text-blue-royal hover:bg-blue-royal hover:text-white-ice' : '',
      color === 'info' ? 'border-blue-sky text-blue-sky hover:bg-blue-sky hover:text-blue-600' : '',
      color === 'gray' ? '!hover:bg-gray-400 border border-gray-300' : '',
      color === 'white' ? ' hover:bg-gray-300 hover:text-gray-800' : '',
    ]"
  >
    <slot />
  </AppButton>
</template>
