<script setup lang="ts">
interface Props {
  labelClasses: string;
  value?: string;
}

defineProps<Props>();
</script>

<template>
  <FormKit
    id="priority"
    name="priority"
    type="dropdown"
    label="Priority"
    :value="value ?? 'low'"
    :deselect="false"
    :options="[
      { label: 'Low', value: 'low', iconClass: 'text-yellow-400' },
      { label: 'Medium', value: 'medium', iconClass: 'text-orange' },
      { label: 'High', value: 'high', iconClass: 'text-red-400' },
    ]"
    :classes="{
      label: labelClasses,
      wrapper: 'flex items-center gap-1.5',
      selector: 'pl-0',
    }"
    :selected-icon="null"
  >
    <template #option="{ option }">
      <div class="flex items-center pl-2">
        <i class="fa-solid fa-fire-flame-simple mr-2 text-lg" :class="option.iconClass" />
        {{ option.label }}
      </div>
    </template>
  </FormKit>
</template>
