<script setup lang="ts">
import { AppButton } from "@/components/app";
import { ButtonProps } from "./buttonProps";

defineProps<ButtonProps>();
</script>

<template>
  <AppButton
    :color="color"
    :is-small="isSmall"
    :leading-icon="leadingIcon"
    :trailing-icon="trailingIcon"
    :is-loading="isLoading"
    :is-rounded="isRounded"
    :disabled="disabled"
    :class="[
      'border border-transparent bg-transparent transition focus:font-bold focus:shadow-inner active:font-bold active:shadow-inner',
      color === 'danger' ? 'text-red-500 hover:border-red-500' : '',
      color === 'primary' ? 'text-blue hover:border-blue' : '',
    ]"
  >
    <slot />
  </AppButton>
</template>
