<script setup lang="ts">
import PageBreadcrumbs from "./PageBreadcrumbs.vue";
import { AppHeader } from "../app";
</script>

<template>
  <AppHeader data-testid="page-header-container">
    <template #title>
      <div class="min-w-fit">
        <PageBreadcrumbs class="pb-2" />
        <h1 class="whitespace-nowrap text-lg font-bold md:text-3xl" data-test="page-title" data-testid="page-title">
          <slot name="title" />
        </h1>
        <template v-if="$slots.secondaryTitle">
          <slot name="secondaryTitle" />
        </template>
        <slot v-if="$slots.meta" name="meta" />
      </div>
    </template>
    <template #actions>
      <div class="flex flex-wrap items-center gap-2">
        <slot v-if="$slots.actions" name="actions" />
      </div>
    </template>
  </AppHeader>
</template>
