<script setup lang="ts">
import { LayoutBox } from "@/components/layout";
</script>

<template>
  <LayoutBox class="flex h-full w-full flex-col justify-between overflow-hidden">
    <header v-if="$slots.title" class="sticky top-0 flex items-center justify-between border-b p-6">
      <p class="text-2xl font-semibold">
        <slot name="title" />
        <span v-if="$slots.headerControls">
          <slot name="headerControls" />
        </span>
      </p>
    </header>
    <div class="grow overflow-y-auto p-6">
      <slot />
    </div>
    <footer v-if="$slots.footer" class="card-footer tg-card-footer">
      <slot name="footer" />
    </footer>
  </LayoutBox>
</template>
